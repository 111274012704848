import React, { Component } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'react-emotion';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';

import Container from '../container';
import Slider from "react-slick";

const Background = styled.div`
  ${tw`w-full py-4 md:py-8`}
`

const LeadTitle = styled.h2
  `${tw`text-center my-2 uppercase text-lg md:text-2xl`}
  font-family: Open sans
`

const LeadText = styled.p
  `${tw`w-full text-xs leading-none mx-auto text-center mb-6 md:mb-8 md:text-base`}`

const FeaturedProductList = styled.div
  `${tw`block`}`

const CheckoutButton = styled(Link)`
  ${tw`text-white rounded-full mb-8 px-10 py-2 border-none block text-center`}
  text-decoration: none;
  background-color: #55BD22;
`
const CardContainer = styled.div`
  ${tw`pb-6 px-1 md:px-3 w-full m-0 flex-grow`};
`
const Card = styled.div
  `
    ${tw`overflow-hidden shadow w-full h-full`}
    border-radius:1rem;
  `

const ProductLink = styled(Link)`
  ${tw`flex flex-no-grow flex-col h-full`};
  color: inherit;
  text-decoration: inherit;
`

const ProductDetails = styled.div
  `
    ${tw`p-2 text-center flex flex-col justify-between flex-grow`}
  `

const ProductImage = styled.img
  `${tw`w-full mb-0 block`}`

const ProductName = styled.h3
  `${tw`mb-3 text-sm`}`

const OldPrice = styled.div
  `${tw`line-through mb-0 text-sm`}`

const CurrentPrice = styled.div
  `${tw`text-red text-xl font-bold`}`
const FeaturedProducts = (props) => {
  var featuredSettings = {
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed:5000,
    slidesToShow:4,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
    
  return (
    <StaticQuery
      query={graphql`
        query {
          allFeaturedProduct {
            edges {
              node {
                productId
                title
                type
                price
                duration
                discount_price
                description
                images{url,alt}
                thumbs{url,alt}
                slug
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
        {data.allFeaturedProduct.edges.filter(item=>(item.node.type=='wifi'||item.node.type=='internet')).length?
          <Background>
            <Container>
              <LeadTitle>pilih paket internet kamu</LeadTitle>
              <FeaturedProductList>
                <Slider {...featuredSettings}>{
                    data.allFeaturedProduct.edges.filter(item=>(item.node.type=='wifi'||item.node.type=='internet')).map((product,key) => (
                      <CardContainer key={'featP-'+key}>
                        <Card>
                          <ProductLink
                            to={`${product.node.slug}`}
                            state={product.node}
                          >
                            <ProductImage src={product.node.thumbs[0].url} alt={product.node.thumbs[0].alt} />
                            <ProductDetails>
                              <ProductName>{product.node.title}</ProductName>
                              <div>
                                {product.node.discount_price === product.node.price ? <OldPrice style={{visibility:'hidden'}}>-</OldPrice> : 
                                  <OldPrice>
                                    <CurrencyFormat
                                      value={product.node.price}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'Rp '}
                                    />
                                  </OldPrice>
                                }
                                <CurrentPrice>
                                  <CurrencyFormat
                                    value={(product.node.discount_price === product.node.price) ? product.node.price : product.node.discount_price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp '}
                                  />
                                </CurrentPrice>
                              </div>
                            </ProductDetails>
                          </ProductLink>
                        </Card>
                      </CardContainer>
                    ))
                  }
                </Slider>
              </FeaturedProductList>
              <LeadText>
                Wifi Republic terkoneksi di  Indonesia dan Luar negeri, memiliki akses di lebih dari 130 negara. Device akan langsung aktif dengan mudah. Wifi Republic membuat internet anda sangat mudah di dalam dan luar negeri.
              </LeadText>
            <CheckoutButton to={`/products/internetluarnegeri`}>
              Order Now
            </CheckoutButton>
          </Container>
        </Background>:null}
        {data.allFeaturedProduct.edges.filter(item=>item.node.type=='simcard').length?
        <Background>
            <Container>
              <LeadTitle>pilih sim card negara tujuan kamu</LeadTitle>
              <FeaturedProductList>
                <Slider {...featuredSettings}>{
                    data.allFeaturedProduct.edges.filter(item=>item.node.type=='simcard').map((product,key) => (
                      <CardContainer key={'featP-'+key}>
                        <Card>
                          <ProductLink
                            to={`${product.node.slug}`}
                            state={product.node}
                          >
                            <ProductImage src={product.node.thumbs[0].url} alt={product.node.thumbs[0].alt} />
                            <ProductDetails>
                              <ProductName>{product.node.title}</ProductName>
                              <div>
                                {product.node.discount_price === product.node.price ? <OldPrice style={{visibility:'hidden'}}>-</OldPrice> : 
                                  <OldPrice>
                                    <CurrencyFormat
                                      value={product.node.price}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'Rp '}
                                    />
                                  </OldPrice>
                                }
                                <CurrentPrice>
                                  <CurrencyFormat
                                    value={(product.node.discount_price === product.node.price) ? product.node.price : product.node.discount_price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp '}
                                  />
                                </CurrentPrice>
                              </div>
                            </ProductDetails>
                          </ProductLink>
                        </Card>
                      </CardContainer>
                    ))
                  }
                </Slider>
              </FeaturedProductList>
              <LeadText>
              Sim card luar negeri Wifi Republic dapat langsung aktif tanpa roaming, tanpa registrasi apapun saat sampai di negara tujuan. Internet di luar negeri jadi mudah, hemat dan cepat.
              </LeadText>
            <CheckoutButton to={`/products/simcard`}>
              Order Now
            </CheckoutButton>
          </Container>
        </Background>:null
        }
      </div>)
      }
    />
  )
}

export default FeaturedProducts;